import React from "react";
import '../Js/About.js';
import '../Css/About.css';
import logoImage from '../Images/android-chrome-512x512.png';

function About(){
    return(
        <div className="container my-5">
            <div className="row">
                <div className="col-12">
                    <div className="logoContainer">
                        <img className="logoImage" src={logoImage} alt="logo"/>
                    </div>
                </div>
           </div>
                <h1>About Pristine Housing Solutions</h1>
                <p>At Pristine Housing Solutions, we specialize in offering guaranteed rent to landlords by finding and managing tenants for both long stays and short stays. Our goal is to provide a seamless property management experience that ensures your home remains occupied, clean, and well-maintained.</p>
                <h2>Our Services</h2>
                <ul>
                    <li><strong>Guaranteed Rent:</strong> We provide a reliable income by guaranteeing rent, so you don't have to worry about vacancies or late payments.</li>
                    <li><strong>Tenant Management:</strong> We handle all aspects of finding and managing tenants, from marketing your property and conducting thorough tenant screenings to handling contracts and renewals.</li>
                    <li><strong>Property Maintenance:</strong> Our team ensures your property is well-maintained and clean, conducting regular inspections and addressing maintenance issues promptly.</li>
                    <li><strong>Bill Savings:</strong> By keeping your home filled with reliable tenants, we help you save money on utility bills and other property-related expenses.</li>
                </ul>
                <h2>Why Choose Us?</h2>
                <p>Listing your property with Pristine Housing Solutions offers several advantages:</p>
                <ul>
                    <li><strong>Professional Management:</strong> Our experienced team manages your property efficiently, ensuring that it remains in top condition and that tenants are satisfied.</li>
                    <li><strong>Peace of Mind:</strong> With our guaranteed rent and comprehensive management services, you can rest easy knowing that your investment is in good hands.</li>
                    <li><strong>Flexibility:</strong> We cater to both long stay and short stay tenants, providing you with flexibility and maximizing your rental income.</li>
                    <li><strong>Transparency:</strong> We maintain open and transparent communication with our landlords, keeping you informed about your property's status and any important developments.</li>
                </ul>
                <p>Partner with Pristine Housing Solutions today and experience the benefits of professional property management. Contact us to learn more about how we can help you achieve your property investment goals.</p>
            <div className="row">
                <a href="/contact" className="contactButton btn active">Contact Us</a>
            </div>
        </div>

    )
}

export default About;